import React, { useLayoutEffect, useRef, useState } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import Layout from "../components/layout"
import Img from "gatsby-image"
import ReachOut from "../components/interview/reach-out"
import useSiteMetadata from "../hooks/use-site-metadata"
import SEO from "../components/seo"

const Interview = ({ data, location }) => {
  const { siteUrl } = useSiteMetadata()
  const interview = data.markdownRemark
  const part = interview.frontmatter.date.split("-")
  const date = new Date(part[0], part[1], part[2])
  const options = { year: "numeric", month: "long", day: "numeric" }

  //parallax
  const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()

  const logo = interview.frontmatter.logo

  const x = useTransform(scrollY, [elementTop, elementTop + 2], [0, -1], {
    clamp: false,
  })

  useLayoutEffect(() => {
    const element = ref.current
    setElementTop(element.offsetTop)
  }, [ref])

  const title = `Interview of ${interview.frontmatter.title}, an SESF Spotlight`
  const description = `Learn more about the SESF member ${interview.frontmatter.title}, how they get involved in esports, what they have achieved, what they are doing, what are their goals for the futur.`

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
      </Helmet>
      <SEO
        title={title}
        description={description}
        image={`${siteUrl}${interview.frontmatter.logo.childImageSharp.fixed.src}`}
        author={interview.frontmatter.interviewer}
      />
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        className="breadcrumbs"
      >
        <TransitionLink
          to={"/"}
          className="ariane"
          exit={{
            length: .3,
          }}
          entry={{
            delay: .3,
          }}
        >
          <motion.svg
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 492 492"
            xmlSpace="preserve"
          >
            <g>
              {" "}
              <g>
                {" "}
                <path d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788			C492,219.198,479.172,207.418,464.344,207.418z" />{" "}
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </motion.svg>
          <motion.span
            initial={{ x: 150, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
          >
            back to members
          </motion.span>
        </TransitionLink>
      </motion.div>
      <div className="jumbotron">
        <motion.h1 className="jumbotron__respondent-team" style={{ x }}>
          {interview.frontmatter.title}
        </motion.h1>
        <div className="jumbotron__media">
          {logo && <Img fluid={logo.childImageSharp.fluid} />}
          <span className="jumbotron__media-teamname">
            {interview.frontmatter.title}
          </span>
        </div>
        <div className="jumbotron__right">
          <div ref={ref} className="jumbotron__respondent-infos">
            <motion.span
              initial={{ x: -45, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              className={"jumbotron__respondent"}
            >
              {interview.frontmatter.respondent}
            </motion.span>
            <motion.span
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              className={"jumbotron__role"}
            >
              {interview.frontmatter.role}
            </motion.span>
          </div>
          <div className={"jumbotron__introduction"}>
            <p>{interview.frontmatter.introduction}</p>
          </div>
          <div className="jumbotron__information">
            <div className="jumbotron__information-label">
              Location{" "}
              <span className={"jumbotron__information-text"}>
                {interview.frontmatter.location}
              </span>
            </div>
            <div className="jumbotron__information-label">
              Date{" "}
              <span className={"jumbotron__information-text"}>
                {date.toLocaleString("en-US", options)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <article
        className={"interview-main"}
        dangerouslySetInnerHTML={{ __html: interview.html }}
      />
      <ReachOut
        logo={logo}
        reach={interview.frontmatter}
        teamname={interview.frontmatter.title}
        location={location}
      />
    </Layout>
  )
}

Interview.propTypes = {}

export default Interview

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        twitter
        facebook
        website
        instagram
        date
        featured_quote
        interviewer
        location
        respondent
        membershiptype
        role
        title
        introduction
        logo {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
            fixed(width: 640) {
              src
            }
          }
        }
      }
      html
      excerpt
    }
  }
`
