import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import useSiteMetadata from "../../hooks/use-site-metadata"

const ReachOut = props => {
  const { siteUrl } = useSiteMetadata()
  const sharerFacebook = `https://www.facebook.com/sharer.php?u=${siteUrl}${props.location.pathname}`
  const sharerTwitter = `https://twitter.com/share?url=${siteUrl}${props.location.pathname}`

  return (
    <div className="reach-out">
      <div className="reach-out__col1">
        {
          props.logo &&
          <Img fluid={props.logo.childImageSharp.fluid} />
        }
      </div>
      <div className="reach-out__col2">
        <div className="reach-out__title">Reach out to {props.teamname}</div>
        <ul className={"reach-out__list"}>
          {props.reach.website && (
            <li className={"reach-out__list-item"}>
              <a href={props.reach.website}>Website</a>
            </li>
          )}
          {props.reach.twitter && (
            <li className={"reach-out__list-item"}>
              <a href={props.reach.twitter}>Twitter</a>
            </li>
          )}
          {props.reach.facebook && (
            <li className={"reach-out__list-item"}>
              <a href={props.reach.facebook}>Facebook</a>
            </li>
          )}
          {props.reach.instagram && (
            <li className={"reach-out__list-item"}>
              <a href={props.reach.instagram}>Instagram</a>
            </li>
          )}
        </ul>
        <div className="reach-out__spreadthelove__title">Spread the love</div>
        <ul className={"reach-out__list"}>
          <li className={"reach-out__list-item"}>
            <a href={sharerTwitter}>Share on twitter</a>
          </li>
          <li className={"reach-out__list-item"}>
            <a href={sharerFacebook}>Share on facebook</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

ReachOut.propTypes = {}

ReachOut.defaultProps = {
  teamname: "this team",
  website: "https://www.website.com",
  twitterUrl: "https://www.twitter.com",
  facebookUrl: "https://www.facebook.com",
  shareTwitter: "www.twitter.com",
}

export default ReachOut
